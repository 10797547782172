/** @jsx jsx */
import { jsx } from 'theme-ui';

import React from 'react';
import { Flex, Box, Heading, Text, Message, Link } from 'theme-ui';
import { graphql, Link as GatsbyLink } from 'gatsby';
import { AiOutlineFilePdf } from 'react-icons/ai';
import { Helmet } from 'react-helmet';
import { Trans, t } from '@lingui/macro';

import Breadcrumbs from '../../plugins/storefront/src/components/Breadcrumbs';

const DocumentsPage = (props) => {
  const { storeName } = props.data.site.siteMetadata.gatsbyStorefrontConfig;
  const { hrefLang } = props.pageContext;

  return (
    <>
      <Helmet
        title={t({
          id: `Documents.Title`,
          message: `Documents`,
        })}
        titleTemplate={t`%s — ${storeName}`}
        defer={false}
      >
        <meta
          name="description"
          content={t({
            id: `Documents.MetaDescription`,
            message: `Legal documents`,
          })}
        />
      </Helmet>
      <Flex
        my={3}
        py={[2, 3, 4]}
        sx={{
          width: '100%',
          bg: '#f4f1eb',
          justifyContent: 'center',
        }}
      >
        <Box mx={3}>
          <Breadcrumbs
            productTitle={t({
              id: `Documents.BreadcrumbsTitle`,
              message: `Documents`,
            })}
            separator="/"
          />
        </Box>
      </Flex>

      <Box
        mx="auto"
        px={[3, 2]}
        mt={[1, 4]}
        mb={4}
        sx={{
          width: '100%',
          maxWidth: 900,
        }}
      >
        <Heading as="h1" sx={{ fontSize: [4, 5] }}>
          <Trans id="Documents.Heading">Documents</Trans>
        </Heading>
        <Text as="p" mt={[2, 3]}>
          <Heading as="h2" sx={{ fontSize: [3, 4] }} my={[2, 3]}>
            <Trans id="Documents.WarrantyHeading">Warranty</Trans>
          </Heading>
          <Trans id="Documents.WarrantyDescription">
            <ul>
              <li>
                Per the legislation, the warranty for monotapes is provided for
                one month.
              </li>
              <li>The period of use - six months.</li>
              <li>
                If you follow the recommendations, the monotapes will last a
                long time.
              </li>
            </ul>
          </Trans>
        </Text>

        <Text as="p" mt={[2, 3]}>
          <Heading as="h2" sx={{ fontSize: [3, 4] }} my={[2, 3]}>
            <Trans id="Documents.ReceiptHeading">Receipt</Trans>
          </Heading>
          <Trans id="Documents.ReceiptDescription">
            After the purchase, you will receive an electronic confirmation of
            your payment from our payments provider.
            <br /> We accept only online payments and do not provide physical
            receipts.
          </Trans>
          {hrefLang === 'ru-BY' ? (
            <Box mt={[2, 3]}>
              <Link
                href="/tovarnij-chek.pdf"
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                <AiOutlineFilePdf size="30" />
                <Trans id="Documents.ReceiptBY">Образец товарного чека</Trans>.
              </Link>
            </Box>
          ) : (
            ''
          )}
        </Text>

        <Text as="p" mt={[2, 3]}>
          <Heading as="h2" sx={{ fontSize: [3, 4] }} my={[2, 3]}>
            <Trans id="Documents.PaymentHeading">
              How to place an order and make a payment
            </Trans>
          </Heading>
          <Trans id="Documents.PaymentDescription">
            <ol>
              <li>Add the products to the Cart.</li>
              <li>
                Go to the{' '}
                <Link as={GatsbyLink} to="/cart">
                  Cart
                </Link>{' '}
                and fill in all the fields of the order form, including:{' '}
                <ul>
                  <li>your contact information,</li>
                  <li> shipping address.</li>
                </ul>
              </li>
              <li>
                Click the "Continue to payment" button and select the payment
                method:
                <ul>
                  <li>Visa or MasterCard.</li>
                </ul>
              </li>
            </ol>

            <Message>
              All credit card payments are handled by{' '}
              <Link
                href={
                  hrefLang === 'ru-BY'
                    ? 'https://webpay.by/'
                    : 'https://en.webpay.by/'
                }
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                WEBPAY
              </Link>{' '}
              - our secure payments provider. <br />
              <br />
              The secure WEBPAY server establishes an encrypted connection using
              the secure TLS protocol and privately accepts the payment card
              data from the client (card number, name of the holder, expiration
              date, and the control number of the CVC/CVC2 bank card).
              <br /> <br />
              After making a payment using a bank card, you may save the
              received card receipts.
              <br /> <br />
              Suppose you are waiting too long or have not received the order.
              You need to contact (the technical support service) by phone at
              +375 (29) 183-35-87 or using the{' '}
              <Link as={GatsbyLink} to="/contact">
                feedback form
              </Link>
              . Our managers will assist you.
              <br /> <br />
              When paying with a card (Visa, MasterCard), the funds may be
              refunded only to the card from which the payment was made.{' '}
            </Message>
          </Trans>
        </Text>
      </Box>
    </>
  );
};

export const DocumentsPageQuery = graphql`
  query {
    site {
      siteMetadata {
        gatsbyStorefrontConfig {
          storeName
        }
      }
    }
  }
`;

export default DocumentsPage;
